import { EmployeeAction } from "../model/employee-action.model"
import { EmployeeActivityReq, EmployeeActivitySearchReq } from "../request/employee-activity.request"
import { AttendanceConfigRes } from "../response/attendance.response"
import { EmployeeActivityRes } from "../response/employee-activity.response"
import httpClient from "../util/http-client"

export const EmployeeActivityService = {
    todayActivty: async (): Promise<(AttendanceConfigRes | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.get("/employee-activity/today")
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    addAction: async (employeeActivityReq: EmployeeActivityReq): Promise<(any | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/employee-activity", employeeActivityReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data, err]
    },
    getEmployeeActivityList: async (employeeActivitySearchReq: EmployeeActivitySearchReq): Promise<(EmployeeActivityRes[] | any)[]> => {
        let data
        let err
        try {
            const res = await httpClient.post("/employee-activity-search", employeeActivitySearchReq)
            data = res.data
        } catch (error: any) {
            err = error
        }
        return [data?.data ?? [], err]
    },
}